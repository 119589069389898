import moment from "moment";
import { useEffect, useState } from "react";
import { Themes } from "../../../common/Constant";
import { ShowAlert } from "../../UI/Alert";
import { GetWallet, RewardProducts, GetMemberPartners, GetMyRewards, RedeemRewards } from "../../../common/Request";
import { Navigation } from "../Navigation";
import { useStoreState } from "../../../common/Store";

export const ClaimRewards = () => {

    const userInfo = useStoreState("userinfo");
    const [ products, setProducts ] = useState([]);
    const [ data, setData ] = useState([]);
    const [ myRewards, setMyRewards ] = useState([]);
    const [ partners, setPartners ] = useState([]);
    const [ balance, setBalance ] = useState(0);
    const [ selectedPartner, setSelectedPartner ] = useState("None");

    const categoryFilter = (partner) => {
        setSelectedPartner(partner);
        partner === "None" ? setData([]) : setData(products.filter(d => d.category === partner));
    }

    const redeem = (rdid, name, points) => {
        if(parseFloat(points) > balance) {
            ShowAlert("Failed", "You don't have enought reward points to redeem this item.");
        }else{
            const confirm = window.confirm("Are you sure to redeem this item \"" + name + "\" with " + points + " reward points?");
            if(confirm) {
                RedeemRewards({
                    rdid: rdid,
                    mid: userInfo.mid
                }, res => {
                    if(res.t === "Success") {
                        ShowAlert("Success", "Please proceed to cashier to claim your rewards.");
                        setBalance(balance - parseFloat(points));

                        GetMyRewards({ mid: userInfo.mid }, res => {
                            setMyRewards(res);
                        });
                    }else{
                        ShowAlert(res.t, res.m);
                    }
                })
            }
        }
    }

    useEffect(() => {
        GetWallet({ mid: userInfo.mid }, res => {
            setBalance(parseFloat(res.rpb));
        });

        RewardProducts(res => {
            setProducts(res);
            setData([]);
        });

        GetMyRewards({ mid: userInfo.mid }, res => {
            setMyRewards(res);
        });

        GetMemberPartners(res => {
            setPartners(res);
        })
    }, [ userInfo.mid ]);

    return (
        <div>
            <Navigation />
            <div className="bg-white max-w-4xl w-screen block m-auto">
                <h1 className={ Themes.Header + " bg-rose-700" }>Reward Points Bonus</h1>
                <div className="p-2">
                    <h1 className="font-bold text-rose-700 text-right">Balance: <span className="text-3xl">{ balance }</span><i>pts</i></h1>
                    <h1 className="font-bold text-rose-700 text-xl pb-2">My Rewards</h1>
                    <table className="w-full text-slate-700 text-xs table-auto border-collapse border border-slate-400">
                        <thead>
                            <tr>
                                <th className="border border-slate-300 p-1">Item</th>
                                <th className="border border-slate-300 p-1">Category</th>
                                <th className="border border-slate-300 p-1">Points</th>
                                <th className="border border-slate-300 p-1">Status</th>
                                <th className="border border-slate-300 p-1">Request Date</th>
                            </tr>
                        </thead>
                        {
                            myRewards.length > 0 ?
                                <tbody>
                                    {
                                        myRewards.map(r => (
                                            <tr>
                                                <td className="border border-slate-300 p-1 text-center">{ r.name }</td>
                                                <td className="border border-slate-300 p-1 text-center">{ r.category }</td>
                                                <td className="border border-slate-300 p-1 text-center">{ r.points }</td>
                                                <td className="border border-slate-300 p-1 text-center"><b className={ "text-" + (r.status === "PENDING" ? "amber" : "green") + "-600" }>{ r.status }</b></td>
                                                <td className="border border-slate-300 p-1 text-center">{ moment(r.created).format("MMM DD, YYYY") }</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            : 
                            <caption className="caption-bottom p-2 border border-slate-300 font-bold">
                                No rewards claimed
                            </caption>
                        }
                    </table>
                </div>
                <br />
                <hr />
                <br />
                <div className="p-2">
                    <h1 className="font-bold text-rose-700 text-xl pb-2">
                        Redeem Rewards
                        {/* <h5 className="text-right text-sm">
                            Partner Merchant:
                            <select className="w-92 bg-rose-700 text-white ml-2 px-2 py-1 rounded-md" onChange={ (e) => { categoryFilter(e); } }>
                                <option value="">All Partners</option>
                                {
                                    partners.map(p => (
                                        <option value={ p.partner }>{ p.partner }</option>
                                    ))
                                }
                            </select>
                        </h5> */}
                        <h5 className="text-sm">Select Partner Merchant</h5>
                        <div className="text-center">
                                {
                                    partners.map(p => (
                                        <div className={ "inline-block m-4 cursor-pointer " + (selectedPartner === p.partner ? "" : "grayscale") } onClick={ () => { categoryFilter(p.partner) } }>
                                            <img src={ p.photo } className="h-40" alt="partner-photo1" />
                                            <div className="text-slate-700">{ p.partner }</div>
                                        </div>
                                    ))
                                }
                        </div>
                    </h1>
                    <div className="grid gap-4 grid-cols-1 pb-20 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
                        {
                            data.map(p => (
                                <div className="relative inline-block mb-2">
                                    <img src={ p.photo } alt={ p.photo } className="w-full h-48 object-cover bg-slate-100" />
                                    <h5>{ p.name }</h5>
                                    <h5 className="text-xs">{ p.category }</h5>
                                    <h5 className="text-rose-700 italic font-bold">{ p.points }pts</h5>
                                    <button type="button" onClick={ () => { redeem(p.rdid, p.name, p.points); } } className="bg-rose-700 text-white w-full py-0.5 my-2 rounded-full">Redeem</button>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}