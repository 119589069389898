import { useState } from "react"

export const Toggle = ({ toggled = false, onChange = null }) => {

    const [ on, setOn ] = useState(toggled);

    const toggle = () => {
        const bool = on ? false : true;
        setOn(bool);
        if(onChange !== null) onChange(bool);
    }

    return (
        <div>
            <div onClick={ toggle } data-on={ on } className="inline-block w-12 bg-slate-300 rounded-full p-1 cursor-pointer transition data-[on=true]:bg-blue-200">
                <div data-on={ on } className="w-5 h-5 bg-slate-700 rounded-full transition duration-200 translate-x-0 data-[on=true]:bg-blue-700 data-[on=true]:translate-x-5"></div>
            </div>
        </div>
    )

}